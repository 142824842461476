import React from "react";
import { Row, Col, Form, FormControl, Button, FormGroup, Card } from "react-bootstrap";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { graphql, Link } from "gatsby";
import noimageImg from "../images/noimage.png"




const RecipesPage = ({ data }) => {
  const axiosBase = require('axios');
  const axios = axiosBase.create({
    baseURL: `https://brave-elion-4f5238.netlify.app/.netlify/functions/searchrecipes`,
    responseType: 'json'
  });

  return (
    <Layout>
      <SEO title="Recipes" />

      <Row style={{ maxWidth: `45rem`, marginTop: `2rem` }}>
        <Form id="form" style={{ margin: `auto` }} onSubmit={(e) => {
          const form = document.getElementById("form")
          form.ariaDisabled = true;
          const btn = document.getElementById("searchbutton")
          btn.disabled = true;
          e.preventDefault();
          const textElement = document.getElementById("searchtext")
          const resultElement = document.getElementById("result")
          while (resultElement.firstChild) {
            resultElement.removeChild(resultElement.firstChild);
          }
          axios.get('?q=' + encodeURI(textElement.value))
            .then(response => {
              resultElement.insertAdjacentHTML(`beforeend`, `
                  <div class="col-md-12 col-12"><h5>検索結果 ` + response.data.totalCount + `件</h5></div>
                  `);
              response.data.contents.forEach(contents => {
                var taglinks = ``
                contents.tags.forEach(tags => {
                  taglinks = taglinks + `<a href="/tag/` + tags.id + `" style="margin: 0.5rem;">#` + tags.tagname + `</a>`
                })
                const url = "image" in contents ? contents.image.url + `?fit=crop&amp;w=1000&amp;h=1000&auto=compress,enhance&q=25` : document.getElementById("noimage").value
                resultElement.insertAdjacentHTML(`beforeend`, `
                      <div class="col-md-4 col-12">
                      <div class="card" style="margin-top: 1rem;">
                      <div class="card-header"><a href="/recipe/`+ contents.id + `"><h5>` + contents.title + `</h5></a></div>
                      <div class="card-body">
                      <a href="/recipe/`+ contents.id + `">
                      <img class="card-img"src="`+ url + `" alt="` + contents.title + `">
                      </a>
                      <p class="card-text">`+ taglinks + `</p>
                      </div>
                      </div>
                      </div>
                      `)
              });
            })
            .catch(error => {
              console.error(error);
            })
            .finally(() => {
              form.ariaDisabled =false;
              btn.disabled = false;
            });
        }}>
          <FormGroup controlId="recipssearch">
            <FormControl type="hidden" id="noimage" value={noimageImg} />
            <div className="input-group mb-3">
              <FormControl size="lg" type="text" placeholder="Search Recipes" id="searchtext"  />
              <Button variant="primary" type="submit" id="searchbutton">
                検索
              </Button>
            </div>
          </FormGroup>
        </Form>
      </Row>

      <Row id="result">
      <div class="col-md-12 col-12"><h5>検索結果 {data.allMicrocmsRecipe.totalCount }件</h5></div>
      {data.allMicrocmsRecipe.edges.map(({ node }) => (
        <Col xs={12} md={4} key={node.recipeId}>
          <Card style={{ marginTop: `1rem` }}>
            <Card.Header>
              <Link to={`/recipe/${node.recipeId}`}>
                <h5>{node.title}</h5>
              </Link>
            </Card.Header>
            <Card.Body >
              <Link to={`/recipe/${node.recipeId}`}>
                <Card.Img src={node.image === null ? noimageImg : node.image.url + `?fit=crop&w=1000&h=1000&auto=compress,enhance&q=25`} alt={node.title} />
              </Link>
              <Card.Text>
                {node.tags.map(tags => (
                  <Link to={`/tag/${tags.id}`} style={{ margin: `0.5rem` }} key={tags.id}>
                    {`#` + tags.tagname}
                  </Link>
                ))}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      ))}
      </Row>

      <Row>
        <Col style={{ maxWidth: `60rem`, paddingTop: `3rem` }}></Col>
      </Row>
    </Layout>
  )
};

export default RecipesPage;

export const query = graphql`
query {
  allMicrocmsRecipe(sort: {order: DESC, fields: createdAt}) {
    edges {
      node {
        title
        id
        recipeId
        createdAt
        tags {
          tagname
          id
        }
        image {
          url
        }
      }
    }
    totalCount
  }
}
`